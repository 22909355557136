<template lang="pug">
div.row
  ViewPhotoList(
    :sailorDocument="sailorDigitalDocument"
    documentType="sailorDigitalDocument"
    isHiddenActionsButton
    ).col-sm-12.col-md-6
  div.col-sm-12.col-md-6
    Medical(
      v-if="TYPE_DOCUMENTS_CONSTANTS.MEDICAL_CERTIFICATE === sailorDigitalDocument.type_document"
      @saveDocument="saveDocument").col-sm-12
    Qualification(
      v-if="[TYPE_DOCUMENTS_CONSTANTS.QUALIFICATION, TYPE_DOCUMENTS_CONSTANTS.PROOF_OF_DIPLOMA].includes(sailorDigitalDocument.type_document)"
      @saveDocument="saveDocument"
      :sailorId="sailorDigitalDocument.sailor.id"
      :typeDocument="sailorDigitalDocument.type_document"
      ).col-sm-12
    Experience(
      v-if="[TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE, TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD, TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD].includes(sailorDigitalDocument.type_document)"
      @saveDocument="saveDocument"
      :sailorId="sailorDigitalDocument.sailor.id"
      :typeDocument="sailorDigitalDocument.type_document"
      :files="sailorDigitalDocument.photo").col-sm-12
    Education(
      v-if="TYPE_DOCUMENTS_CONSTANTS.EDUCATION === sailorDigitalDocument.type_document"
      @saveDocument="saveDocument"
    ).col-sm-12.text-left
    ForeignPassport(
      v-if="TYPE_DOCUMENTS_CONSTANTS.FOREIGN_PASSPORT === sailorDigitalDocument.type_document"
      @saveDocument="saveDocument"
      :photos="sailorDigitalDocument.photo"
    ).col-sm-12.text-left
    CitizenPassport(
      v-if="TYPE_DOCUMENTS_CONSTANTS.CITIZEN_PASSPORT === sailorDigitalDocument.type_document"
      @saveDocument="saveDocument"
    ).col-sm-12.text-left
    SailorPassport(
      v-if="TYPE_DOCUMENTS_CONSTANTS.SAILOR_IDENTITY_CARD === sailorDigitalDocument.type_document"
      @saveDocument="saveDocument"
    ).col-sm-12.text-left
</template>
<script>
import { TYPE_DOCUMENTS_CONSTANTS, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { mapActions } from 'vuex'

export default {
  components: {
    Education: () => import('./Forms/Education.vue'),
    Medical: () => import('./Forms/Medical.vue'),
    Qualification: () => import('./Forms/Qualification.vue'),
    Experience: () => import('./Forms/Experience.vue'),
    ForeignPassport: () => import('./Forms/ForeignPassport.vue'),
    CitizenPassport: () => import('./Forms/CitizenPassport.vue'),
    SailorPassport: () => import('./Forms/SailorPassport.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList')
  },
  props: {
    sailorDigitalDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      TYPE_DOCUMENTS_CONSTANTS
    }
  },
  methods: {
    ...mapActions(['saveVerificationDocumentSC']),
    async saveDocument (item) {
      !item.data.body.type_override && (item.data.body.type_override = this.sailorDigitalDocument.type_document)
      if (!item.data.body.additional_info?.status_line) item.data.body.additional_info.status_document = STATUSES_DOCUMENT_CONSTANTS.draft
      await this.saveVerificationDocumentSC(item)
    }
  }
}
</script>
